import { useTranslations } from '@pepita-react/i18n';
import clsx from 'clsx';

import { AgencyPlaceholder } from 'src/components/AgencyPlaceholder';

import type { AGENCY_TYPE } from 'src/constants/real-estate';

import { PepitaBadge } from 'src/libs/ui/pepita-badge';
import { PepitaFigure } from 'src/libs/ui/pepita-figure';

import css from './styles.module.scss';

interface AgencyLogoProps {
  agencyType: AGENCY_TYPE;
  isSmall: boolean;
  imageAlt: string;
  imageUrls?: string;
  highlighted?: boolean;
}

export function AgencyLogo({
  agencyType,
  isSmall,
  imageAlt,
  imageUrls,
  highlighted,
}: AgencyLogoProps) {
  const { trans } = useTranslations();

  return (
    <PepitaFigure
      bordered
      customClass={clsx(
        css['in-agencyLogo__figure'],
        'nd-ratio',
        highlighted && css['is-highlighted'],
        isSmall && css['is-compact']
      )}
    >
      {imageUrls ? (
        <PepitaFigure.Image
          src={imageUrls}
          customClass={clsx(
            css['in-agencyLogo__image'],
            isSmall && css['is-compact']
          )}
          alt={imageAlt}
          height={60}
        />
      ) : (
        <PepitaFigure.Content customClass={css['in-agencyLogo__image']}>
          <AgencyPlaceholder agencyType={agencyType} bordered />
        </PepitaFigure.Content>
      )}

      {highlighted && (
        <PepitaFigure.Overlay>
          <PepitaBadge
            variant="brand"
            position="topLeft"
            customClass={css['in-agencyLogo__badge']}
          >
            {trans('in_evidenza')}
          </PepitaBadge>
        </PepitaFigure.Overlay>
      )}
    </PepitaFigure>
  );
}
