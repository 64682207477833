import type {
  ForwardRefExoticComponent,
  MutableRefObject,
  ReactNode,
  RefAttributes,
  SyntheticEvent,
} from 'react';
import { forwardRef, useEffect, useRef } from 'react';
import { clsx } from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { useAtomicStateAtomValue } from 'src/atoms/atomic-state';

import { geographySearchParamsAtom } from 'src/components/GeographySearch/atoms';

import { getProductConfig } from 'src/config/product';

import { useQueryParams } from 'src/hooks/useQueryParams';

import { BREADCRUMB_TYPE } from 'src/types/breadcrumb';
import type { SearchParams } from 'src/types/search';
import type { Language } from 'src/types/translations';

import { deserializeFromUrl, serializeIntoUrl } from 'src/utils/querystring';
import { alternateAbsoluteURL } from 'src/utils/url';

import css from './styles.module.scss';

type BreadcrumbLinkProps = {
  href?: string;
  isSelected?: boolean;
  role?: string;
  itemProp?: string;
  link?: { url: string; title?: string; follow?: Nullable<boolean> };
  children?: ReactNode;
  type?: BREADCRUMB_TYPE;
  isDropdownLink?: boolean;
  isDropdownLabel?: boolean;
  onClick?: (event: SyntheticEvent) => void;
  serverSideNavigation?: boolean;
};

const paramsToExclude = [
  'fkRegione',
  'idProvincia',
  'idComune',
  'idMZona',
  'idQuartiere',
  'idZona',
  'idLocalita',
  'idMacroarea',
  'idAreaTuristica',
  'pag',
  'fkMetro',
  'fkMetroStation',
  'vrt',
];

const getLowerGeographyType = (
  geoParams: SearchParams
): Nullable<BREADCRUMB_TYPE> => {
  if (geoParams.idQuartiere || geoParams.fkMetroStation) {
    return BREADCRUMB_TYPE.QUARTIERE;
  }

  if (geoParams.idMZona || geoParams.fkMetro) {
    return BREADCRUMB_TYPE.ZONA;
  }

  if (geoParams.idMacroarea) {
    return BREADCRUMB_TYPE.MACROAREA;
  }

  if (geoParams.indirizzo) {
    return BREADCRUMB_TYPE.ADDRESS;
  }

  if (geoParams.idComune) {
    return BREADCRUMB_TYPE.COMUNE;
  }

  if (geoParams.idProvincia) {
    return BREADCRUMB_TYPE.PROVINCIA;
  }

  if (geoParams.idRegione) {
    return BREADCRUMB_TYPE.REGIONE;
  }

  return null;
};

export const BreadcrumbLink: ForwardRefExoticComponent<
  BreadcrumbLinkProps & RefAttributes<HTMLAnchorElement>
> = forwardRef(
  (
    {
      href,
      isSelected,
      role,
      itemProp,
      link,
      type,
      onClick,
      children,
      isDropdownLabel,
      isDropdownLink,
      serverSideNavigation = false,
    },
    ref
  ) => {
    const defaultRef = useRef<HTMLAnchorElement>();
    const linkRef = (ref || defaultRef) as MutableRefObject<HTMLAnchorElement>;

    const currentGeography = useAtomicStateAtomValue(geographySearchParamsAtom);

    // get current url query params except for map params
    const { zoom, lat, lng, ...queryParamsForLink } = useQueryParams();
    const router = useRouter();

    paramsToExclude.forEach((param) => (queryParamsForLink[param] = undefined));

    // TODO: check if this is still needed
    // Use a listener to add query params to links and blocks url to current page
    useEffect(() => {
      if (serverSideNavigation) {
        return;
      }

      const clickListener = (event) => {
        event.preventDefault();

        // Get current page path
        const tempLink = document.createElement('a');

        // add locale to href because asPath doesn't have it
        tempLink.href = alternateAbsoluteURL(
          router.asPath,
          router.defaultLocale as Language,
          router.locale as Language
        );
        const currentPath = tempLink.pathname;

        // Get current url query params from server
        const queryParamsFromServer = (linkRef?.current?.search &&
          deserializeFromUrl(linkRef.current.search)) as SearchParams;

        // Compare geography types in NOT SEO pages, and compare paths in SEO pages
        const isCurrentGeography =
          '/search-list/' === currentPath
            ? getLowerGeographyType(queryParamsFromServer) ===
                getLowerGeographyType(currentGeography) && isSelected !== false
            : linkRef.current.pathname === currentPath;

        const homepagePath = `/${
          router.defaultLocale !== router.locale ? `${router.locale}/` : ''
        }`;

        // If next path is the homepage we have to navigate in every case
        if (
          href &&
          (linkRef.current.pathname === homepagePath || !isCurrentGeography)
        ) {
          if (linkRef.current.pathname === homepagePath) {
            // Go to the homepage
            window.location.href = alternateAbsoluteURL(
              '/',
              router.defaultLocale as Language,
              router.locale as Language
            );
          } else if (
            linkRef.current.pathname === getProductConfig('searchAgenciesUrl')
          ) {
            router.push(linkRef.current.href);
          } else if (type === BREADCRUMB_TYPE.REGIONE) {
            router.push(linkRef.current.href);
          } else {
            // Redirect with query params
            router.push(
              serializeIntoUrl(linkRef.current.pathname, {
                ...queryParamsForLink,
                ...queryParamsFromServer,
              })
            );
          }
        }
      };

      if (linkRef.current) {
        linkRef.current.addEventListener('click', clickListener);
      }

      return () => {
        if (linkRef.current) {
          linkRef.current.removeEventListener('click', clickListener);
        }
      };
    }, [router]);

    // onClick is defined only to open dropdown
    const handleClick = serverSideNavigation ? undefined : onClick;

    const anchor = (
      <a
        href={serverSideNavigation ? href : undefined}
        ref={linkRef}
        className={clsx(
          isDropdownLabel && css['in-breadcrumbLink__dropdownLabel'],
          isDropdownLink && css['in-breadcrumbLink__dropdownLink']
        )}
        role={role || 'link'}
        itemProp={itemProp}
        title={link?.title}
        rel={link?.follow === false ? 'nofollow' : undefined}
        onClick={handleClick}
        onKeyDown={
          handleClick
            ? (event) => {
                if (event.key === 'Enter' || event.keyCode === 13) {
                  handleClick(event);
                }
              }
            : undefined
        }
      >
        {children}
      </a>
    );

    return !serverSideNavigation && href ? (
      <Link href={href} passHref legacyBehavior>
        {anchor}
      </Link>
    ) : (
      anchor
    );
  }
);

BreadcrumbLink.displayName = 'BreadcrumbLink';
