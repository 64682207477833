import { memo } from 'react';
import listingSprite from '@pepita-fe/sprite-listing-search/sprite.svg';
import { useTranslations } from '@pepita-react/i18n';
import type { Option } from '@pepita-react/select/src/types';
import clsx from 'clsx';

import { PepitaSelect } from 'src/libs/ui/pepita-select';

import css from './styles.module.scss';

interface SortingSelectProps<T extends Option> {
  options: T[];
  value: T;
  onChange: (option: T) => void;
  customClass?: string;
  controlCustomClass?: string;
  isDisabled?: boolean;
}

function SortingSelectComponent<T extends Option>({
  options,
  value,
  onChange,
  customClass,
  controlCustomClass,
  isDisabled,
}: SortingSelectProps<T>) {
  const { trans } = useTranslations();

  return (
    <PepitaSelect
      disabled={isDisabled}
      placeholder={trans('act_order', {
        capitalize: true,
      })}
      title={trans('act_order', {
        capitalize: true,
      })}
      customClass={clsx(css['in-sortingSelect'], customClass)} // necessary for old saved map
      controlCustomClass={clsx('cy-sorting-select', controlCustomClass)}
      options={options}
      value={value}
      onChange={(option) => onChange(option as T)}
      icon={{
        name: 'order',
        spriteUrl: listingSprite,
      }}
      confirmLabel={trans('act_confirm')}
    />
  );
}

export const SortingSelect = memo(
  SortingSelectComponent
) as typeof SortingSelectComponent;
